import React from 'react';

const IconLogo = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="2048.000000pt"
    height="2048.000000pt"
    viewBox="0 0 2048.000000 2048.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path
        d="M9675 19599 c-1524 -95 -2982 -547 -4290 -1332 -890 -534 -1715
        -1244 -2381 -2047 -485 -586 -856 -1158 -1195 -1843 -563 -1138 -880 -2338
        -959 -3627 -13 -213 -13 -867 0 -1080 94 -1547 542 -2996 1333 -4315 534 -890
        1244 -1715 2047 -2381 586 -485 1158 -856 1843 -1195 1138 -563 2338 -880
        3627 -959 213 -13 867 -13 1080 0 1547 94 2996 542 4315 1333 890 534 1715
        1244 2381 2047 485 586 856 1158 1195 1843 563 1138 880 2338 959 3627 13 213
        13 867 0 1080 -94 1547 -542 2996 -1333 4315 -534 890 -1244 1715 -2047 2381
        -586 485 -1158 856 -1843 1195 -1138 563 -2338 880 -3627 959 -191 11 -913 11
        -1105 -1z m1081 -639 c559 -32 1189 -125 1634 -241 1086 -283 2224 -827 3077
        -1470 570 -430 1087 -924 1551 -1482 786 -944 1408 -2170 1731 -3407 116 -445
        209 -1075 241 -1634 13 -229 13 -803 0 -1032 -32 -559 -125 -1189 -241 -1634
        -283 -1086 -827 -2224 -1470 -3077 -430 -570 -924 -1087 -1482 -1551 -944
        -786 -2170 -1408 -3407 -1731 -445 -116 -1075 -209 -1634 -241 -229 -13 -803
        -13 -1032 0 -559 32 -1189 125 -1634 241 -1086 283 -2224 827 -3077 1470 -570
        430 -1087 924 -1551 1482 -786 944 -1408 2170 -1731 3407 -116 445 -209 1075
        -241 1634 -13 229 -13 803 0 1032 32 559 125 1189 241 1634 283 1086 827 2224
        1470 3077 430 570 924 1087 1482 1551 944 786 2170 1408 3407 1731 434 113
        1078 209 1610 240 203 12 853 12 1056 1z"
      />
      <path
        d="M7081 17016 l-435 -435 1442 -1443 1441 -1443 -1692 -1692 -1692
        -1693 -1442 1442 -1443 1443 -429 -430 -429 -430 3334 -3340 3334 -3340 433
        433 432 432 -1543 1543 -1542 1542 1693 1693 1692 1692 1543 -1542 1542 -1543
        437 438 438 437 -3335 3335 c-1834 1834 -3337 3335 -3340 3335 -3 0 -201 -195
        -439 -434z"
      />
      <path
        d="M13208 13013 l-346 -348 938 -945 939 -945 -2984 -2985 -2985 -2985
        433 -433 432 -432 2985 2985 2985 2985 940 -940 940 -940 353 353 352 352
        -2313 2313 c-1271 1271 -2314 2312 -2317 2312 -3 0 -161 -156 -352 -347z"
      />
      <path
        d="M15640 9345 l-34 -35 47 -48 47 -47 -73 3 c-63 3 -81 0 -123 -21 -59
        -30 -104 -76 -136 -137 -33 -64 -32 -174 2 -245 31 -64 131 -164 195 -195 69
        -34 181 -34 242 -2 66 34 110 78 139 138 23 46 26 64 22 124 l-4 70 68 -67
        c78 -78 98 -113 98 -177 0 -65 -20 -105 -74 -153 -60 -52 -116 -68 -179 -49
        l-45 14 -38 -39 -38 -39 50 -22 c110 -48 236 -17 338 84 96 97 120 220 65 335
        -16 34 -93 119 -268 296 -134 136 -250 247 -256 247 -6 0 -26 -16 -45 -35z
        m92 -197 c88 -26 178 -144 178 -233 -1 -79 -63 -166 -142 -199 -81 -34 -151
        -13 -234 69 -105 104 -112 208 -21 304 61 64 136 84 219 59z"
      />
      <path
        d="M15030 8724 c-106 -46 -179 -180 -151 -279 l10 -39 -42 42 c-24 23
        -47 42 -53 42 -6 0 -25 -16 -44 -35 l-34 -36 227 -226 226 -227 36 34 c19 19
        35 39 35 45 0 5 -61 71 -136 145 -141 140 -173 187 -174 252 0 99 88 188 185
        188 59 0 92 -24 247 -178 l148 -147 37 38 38 37 -150 151 c-177 177 -223 209
        -310 209 -33 -1 -76 -8 -95 -16z"
      />
      <path
        d="M14523 8197 c-79 -29 -164 -113 -201 -197 -8 -19 -15 -65 -15 -101
        -2 -171 165 -340 337 -341 120 -1 230 72 288 189 40 82 43 162 9 238 l-22 49
        -41 -41 c-38 -39 -41 -44 -30 -68 15 -32 15 -104 1 -142 -25 -68 -119 -132
        -192 -133 -39 0 -118 32 -138 57 -11 12 17 43 166 193 l178 178 -16 26 c-20
        30 -90 79 -136 94 -43 15 -146 14 -188 -1z m152 -96 c22 -10 48 -27 57 -38 17
        -18 12 -24 -126 -162 l-144 -144 -27 41 c-52 79 -43 171 24 245 65 72 141 93
        216 58z"
      />
      <path
        d="M13990 7684 c-59 -33 -96 -69 -128 -124 -69 -116 3 -260 130 -260 45
        0 70 10 218 93 80 45 112 47 151 8 63 -64 16 -165 -94 -201 -33 -10 -45 -10
        -84 5 l-45 17 -34 -33 c-39 -38 -35 -49 32 -75 79 -30 163 -9 251 63 122 98
        130 254 17 324 -28 17 -46 21 -92 17 -47 -3 -74 -14 -155 -62 -122 -71 -152
        -78 -195 -41 -102 86 56 253 180 189 30 -15 31 -15 64 17 19 18 34 36 34 39 0
        3 -20 16 -45 28 -65 31 -146 30 -205 -4z"
      />
      <path
        d="M13372 7337 c-105 -106 -192 -196 -192 -202 0 -5 12 -22 27 -37 l27
        -28 83 82 83 83 260 -260 260 -260 37 38 38 37 -260 260 -260 260 78 78 c42
        42 77 82 77 88 0 13 -43 54 -56 54 -5 0 -96 -87 -202 -193z"
      />
      <path
        d="M12837 6853 l-37 -38 125 -125 125 -125 -73 3 c-63 3 -81 0 -123 -21
        -59 -30 -104 -76 -136 -137 -33 -64 -32 -174 2 -245 31 -64 131 -164 195 -195
        69 -34 181 -34 242 -2 66 34 110 78 139 138 23 46 26 64 22 124 l-4 70 48 -47
        48 -47 35 34 c19 19 35 39 35 45 0 10 -590 605 -600 605 -3 0 -22 -17 -43 -37z
        m245 -355 c88 -26 178 -144 178 -233 -1 -79 -63 -166 -142 -199 -81 -34 -151
        -13 -234 69 -105 104 -112 208 -21 304 61 64 136 84 219 59z"
      />
      <path
        d="M12488 6201 c-39 -45 -61 -111 -57 -170 l4 -50 -35 34 c-20 19 -40
        35 -46 35 -6 0 -25 -16 -44 -35 l-34 -36 227 -226 226 -227 36 34 c19 19 35
        39 35 45 0 5 -59 69 -131 140 -133 132 -169 182 -169 232 1 36 29 99 65 141
        l28 34 -40 40 -40 40 -25 -31z"
      />
      <path
        d="M12120 5825 l-34 -35 47 -48 47 -47 -73 3 c-63 3 -81 0 -123 -21 -59
        -30 -104 -76 -136 -137 -33 -64 -32 -174 2 -245 31 -64 131 -164 195 -195 69
        -34 181 -34 242 -2 66 34 110 78 139 138 23 46 26 64 22 124 l-4 70 48 -47 48
        -47 35 34 c19 19 35 39 35 45 0 13 -432 445 -446 445 -5 0 -25 -16 -44 -35z
        m92 -197 c88 -26 178 -144 178 -233 -1 -79 -63 -166 -142 -199 -81 -34 -151
        -13 -234 69 -105 104 -112 208 -21 304 61 64 136 84 219 59z"
      />
      <path
        d="M11622 5327 c-18 -18 -32 -36 -32 -39 0 -4 56 -100 123 -215 68 -114
        129 -218 136 -231 9 -18 -40 3 -237 102 l-249 125 -39 -40 -39 -40 123 -244
        c67 -135 122 -247 122 -250 0 -2 -105 59 -232 135 l-232 140 -38 -37 c-21 -20
        -35 -40 -30 -44 9 -10 564 -319 572 -319 3 0 23 18 45 40 l39 39 -122 245
        -121 245 244 -122 245 -122 41 42 42 41 -157 283 c-87 156 -161 287 -165 292
        -4 4 -21 -8 -39 -26z"
      />
      <path
        d="M10839 4507 c-91 -34 -165 -103 -206 -194 -35 -76 -30 -184 12 -261
        33 -62 104 -131 166 -163 66 -33 167 -37 242 -9 70 27 152 103 188 175 34 71
        34 189 0 258 -31 64 -114 147 -176 177 -60 29 -170 37 -226 17z m161 -108
        c116 -52 188 -176 160 -278 -12 -45 -67 -114 -110 -136 -88 -45 -170 -26 -255
        59 -105 105 -113 209 -22 305 66 69 146 87 227 50z"
      />
      <path
        d="M10220 4175 l-34 -35 127 -128 127 -127 -145 -145 -145 -145 -123
        123 c-67 67 -127 122 -133 122 -5 0 -25 -16 -44 -35 l-34 -36 292 -291 291
        -292 36 34 c19 19 35 39 35 44 0 6 -58 69 -130 141 l-130 130 145 145 145 145
        135 -135 135 -134 35 34 c19 19 35 39 35 45 0 13 -562 575 -576 575 -5 0 -25
        -16 -44 -35z"
      />
    </g>
  </svg>
);

export default IconLogo;
